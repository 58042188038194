import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faGithub, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { useEffect, useState } from 'react';
import './content.css'


export default function Content() {
    const [content, setContent] = useState("") 
    useEffect(() => {
            const getContent = async () => {
            const data = await fetch('https://api.alansnow.dev/content');
            const json = await data.json();
            setContent(json['content']);
        }

        getContent()
        .catch(console.error);
    }, [])
    
    return (
        <section className='content-container'>
            <h1 id="pageContent">&nbsp;{content}&nbsp;</h1>
            <div className="item social">
                <a href="https://www.linkedin.com/in/alancsnow/">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a href="https://www.instagram.com/alan.snow">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://github.com/SnowBiz">
                    <FontAwesomeIcon icon={faGithub} />
                </a>
            </div>
        </section>
    );
}


