import Content from '../content/content';
import logo from './logo.png';
import clouds from './clouds.svg';
import './home.css';

export default function Home() {
    return (
        <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <img src={clouds} className="App-logo-clouds" alt="clouds" />
          <Content/>
        </header>
      </div>  
    );
}